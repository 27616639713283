.badge {
  &.outline {
    &.badge-info {
      border-color: $color-accent;
      color: $color-accent;
    }

    &.badge-success, &.badge-green {
      border-color: $color-green;
      color: $color-green;
    }

    &.badge-warning, &.badge-yellow {
      border-color: $color-yellow;
      color: $color-yellow;
    }

    &.badge-error, &.badge-danger {
      border-color: $color-red;
      color: $color-red;
    }

    &.badge-accent {
      border-color: $color-accent;
      color: $color-accent;
    }

    &.badge-gray {
      border-color: $color-gray;
      color: $color-gray;
    }

    &.badge-light-gray {
      border-color: $color-light-gray;
      color: $color-light-gray;
    }

    &.badge-dark-gray {
      border-color: $color-dark-gray;
      color: $color-dark-gray;
    }

    &.badge-black {
      border-color: $color-black;
      color: $color-black;
    }
  }

  &.badge-success, &.badge-green {
    background-color: $color-green;
    color: #FFFFFF;
  }

  &.badge-warning, &.badge-yellow {
    background-color: $color-yellow;
    color: #FFFFFF;
  }

  &.badge-error, &.badge-danger {
    background-color: $color-red;
    color: #FFFFFF;
  }

  &.badge-accent {
    background-color: $color-accent;
    color: #FFFFFF;
  }

  &.badge-gray {
    background-color: $color-dark-gray;
    color: #FFFFFF;
  }

  &.badge-black {
    background-color: $color-black;
    color: #FFFFFF;
  }

  &.bg-accent {
    background-color: $color-dark-gray;
  }

  &.bg-gray {
    background-color: $color-light-gray;
    color: $color-dark-gray;
  }
}