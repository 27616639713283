.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  @include directify($directions) {
    text-align: directed('left');
  }
  h5.card__title-center {
    text-align: center;
  }
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  .subhead__events {
    @extend .subhead;
    font-size: 14px !important;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.card__with_background_icon--actor {
  overflow: hidden;

  svg {
    position: absolute;
    height: 120px;
    width: 120px;
    top: calc(50% - 60px);
    opacity: 0.3;
    @include directify($directions) {
      #{directed('right')}: -23px;
    }
    color: #ffffff;
  }

  .card__with_background_icon-indicators-label--actor {
    float: right;
    margin-top: 10px;
    position: relative;
  }

}

.card__with_background_icon_decoration--actor {
  @extend .card__with_background_icon--actor;

  border-top: solid 1pt #40e5e8;

  a:hover {
    color: #40e5e8;
  }
  svg {
    fill: #579da9;
  }

  .card__with_background_icon-indicators-label--actor {
    color: #579da9;
  }
}

.card-header__events {
  border-radius: 5px;
  padding: 25px;
  cursor: pointer;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card-header__events:hover {
  background: #38373f;
}

.card-body__tlp-white {
  border-radius: 5px;
  border-left: 4px solid #ffffff;
}

.card-body__tlp-green {
  border-radius: 5px;
  border-left: 4px solid #b8e986;
}

.card-body__tlp-amber {
  border-radius: 5px;
  border-left: 4px solid #f6da6e;
}

.card-body__tlp-red {
  border-radius: 5px;
  border-left: 4px solid #ff4861;
}

.card__title__events {
  @extend .card__title;
  margin-bottom: 20px;
}

.card-body__priority-low {
  border-radius: 5px;
  border-left: 4px solid #b8e986;
}

.card-body__priority-medium {
  border-radius: 5px;
  border-left: 4px solid #f6da6e;
}

.card-body__priority-high {
  border-radius: 5px;
  border-left: 4px solid #ff4861;
}
