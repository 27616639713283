.form__form-group-input-wrap-filter {
  @extend .form__form-group-input-wrap;

  margin-bottom: 30px;
}

.form__select-filtered {
  width: 100%;
  height: 200px;
  margin: 20px 0;

  border: 1px solid #33333a;
  background-color: transparent;
  color: white;
  outline: none;

  option:checked {
    background: #6c757d -webkit-linear-gradient(bottom, #6c757d 0%, #6c757d 100%);
  }
}