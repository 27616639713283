
.devices-container {

  .event-export-btn {
    float: right;
    border: 1px solid #e7e2e2;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;

    .dropdown {
      display: flex;
      height: 33px;
      color: #e7e2e2;

      span[data-toggle=dropdown] {
        padding: 5px 25px;
      }

      .dropdown-menu {
        color: #e7e2e2;
        background-color: #2a2a31;
        border: 1px solid #e7e2e2;
        padding: 5px;

        .event-format {

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}