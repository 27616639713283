
.emitter-details--card {
  .card__title {
    .subhead-container {
      display: flex;
      align-items: center;

      .api-key-display-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 0 12px;
        border: 0;

        &:hover, &:focus, &:active, &:not(:active) {
          background-color: transparent;
          border: 0;

          &:before {
            background-color: transparent;
            border: 0; 
          }
        }
      }
    }
  }

  .del-emitter-btn {
    margin-left: 12px;
  }
}

.emitter-provision-card {
  .emitter-script {
    text-transform: none;
  }
}